/** @jsx jsx */
import { jsx } from "theme-ui";
import { useContext, useEffect } from "react";
import { renderSnippet } from "../utils/functions";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import ShoppingCartButton from "../components/ShoppingCartButton";
import { CartContext } from "../providers/CartProvider";
import { useCart } from "../hooks/useCart";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { Fragment } from "react";
import useAPI from "../hooks/useApi";

const ShoppingCart = () => {
  const { globalState } = useContext(CartContext);
  const { data, postOrder } = useAPI();

  const {
    addProductFromCheckout,
    subtractProductFromCart,
    removeProductFromCart,
  } = useCart();

  function deleteProduct(product, color) {
    removeProductFromCart(product, color);
    postOrder();
  }

  function addProduct(product, color) {
    addProductFromCheckout(product, color);
    postOrder();
  }

  function subtractProduct(product, color) {
    subtractProductFromCart(product, color);
    postOrder();
  }

  // CREATE ORDER when Shopping cart page is rendered
  useEffect(() => {
    postOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    renderSnippet(data);
  }, [data]);

  return (
    <Layout>
      <div sx={{ mx: [4, 5] }}>
        <div
          sx={{
            display: "flex",
            flexDirection: ["column", null, "row"],
            pt: ["100px", 8],
            pb: [4, null, 6],
          }}
        >
          <section
            sx={{
              width: "100%",
              flex: 1,
              borderRight: ["none", null, "1px solid #333"],
            }}
          >
            <h1
              sx={{
                fontFamily: "body",
                color: "text",
                fontSize: 5,
                textTransform: "uppercase",
                textAlign: ["center"],
                mb: [5],
              }}
            >
              Shopping Cart
            </h1>
            {globalState.cart.length < 1 && (
              <p sx={{ fontSize: 3, textAlign: "center", my: 4 }}>
                It seems like your bag is still empty
              </p>
            )}
            {globalState.cart.map((product, index) => {
              const colorImage = product.frontmatter.imagegallery.filter(
                ({ productcolor }) =>
                  Object.keys(product).includes(productcolor?.toLowerCase())
              );
              const color = colorImage[0].productcolor.toLowerCase();
              const quantity = product[color].quantity;
              const image = colorImage[0].image;

              return (
                <Fragment key={index}>
                  <div
                    sx={{
                      display: "flex",
                      height: ["150px", "300px", "250px"],
                      mb: 5,
                    }}
                  >
                    <div sx={{ flex: 1, div: { height: "100%" } }}>
                      <Link to={product.fields.slug}>
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: image,
                            alt: product.frontmatter.title,
                          }}
                        />
                      </Link>
                    </div>
                    <div
                      sx={{
                        flex: [2, 1, 2],
                        display: "flex",
                        flexDirection: "column",
                        pl: [3, 6],
                      }}
                    >
                      <div sx={{ display: "flex" }}>
                        <h3 sx={{ fontSize: [2], mb: 2 }}>
                          {product.frontmatter.title}
                        </h3>
                        <button
                          onClick={() => deleteProduct(product, color)}
                          sx={{
                            border: "none",
                            outline: "none",
                            padding: "0",
                            position: "relative",
                            cursor: "pointer",
                            background: "transparent",
                            ml: "auto",
                            display: "flex",
                            mt: ["3px"],
                          }}
                        >
                          <span
                            sx={{
                              fontSize: 1,
                              display: ["none", "block"],
                              mr: 2,
                            }}
                          >
                            Remove
                          </span>
                          <div
                            sx={{
                              span: {
                                display: "block",
                                padding: "0",
                                height: "2px",
                                backgroundColor: "text",
                                my: "5px",
                                borderRadius: "2px",
                                width: "15px",
                                transition: "ease-in-out 0.3s",
                                mr: [0, null, 5],
                              },
                            }}
                          >
                            <span
                              sx={{
                                transform:
                                  "rotate(-135deg) translate(-3px, -2px)",
                              }}
                            />
                            <span
                              sx={{
                                transform:
                                  "rotate(135deg) translate(-3px, 2px)",
                              }}
                            />
                          </div>
                        </button>
                      </div>

                      <p sx={{ pb: [2] }}>Color: {color}</p>
                      <div sx={{ display: "flex", alignItems: "center" }}>
                        <div
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 20px)",
                            my: 4,
                            gridGap: 3,
                          }}
                        >
                          <ShoppingCartButton
                            handleClick={() => subtractProduct(product, color)}
                            product={product}
                            color={color}
                          >
                            -
                          </ShoppingCartButton>
                          <p sx={{ m: "auto" }}>{quantity}</p>

                          <ShoppingCartButton
                            handleClick={() => addProduct(product, color)}
                            product={product}
                            color={color}
                          >
                            +
                          </ShoppingCartButton>
                        </div>

                        <p sx={{ ml: "auto", mr: [0, null, 5] }}>
                          {parseInt(product.frontmatter.price) * quantity} SEK
                        </p>
                      </div>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </section>
          <section
            sx={{
              width: "100%",
              flex: 1,
              ml: [0, null, 5],
              mt: [6, 0],
              mb: 6,
            }}
          >
            <h1
              sx={{
                fontFamily: "body",
                color: "text",
                fontSize: 5,
                textTransform: "uppercase",
                textAlign: ["center"],
                mb: [5],
              }}
            >
              Payment
            </h1>
            {globalState.cart.length >= 1 && (
              <Fragment>
                <div sx={{ display: "flex", mr: [0, null, 5] }}>
                  <div>Subtotal</div>
                  <div sx={{ ml: "auto" }}> SEK {globalState.totalprice}</div>
                </div>
                <div sx={{ display: "flex", mr: [0, null, 5] }}>
                  <div>Shipping</div>
                  <div sx={{ ml: "auto" }}> Free </div>
                </div>
              </Fragment>
            )}
            <div
              sx={{
                height: "1px",
                backgroundColor: "details",
                my: 3,
                display: "block",
                mr: [0, null, 5],
              }}
            />
            <div sx={{ display: "flex", mr: [0, null, 5], mb: [7] }}>
              <div>TOTAL</div>
              <div sx={{ ml: "auto" }}> SEK {globalState.totalprice}</div>
            </div>
            <div sx={{ height: "100%" }} id="my-checkout-container" />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default ShoppingCart;
