/** @jsx jsx */
import { jsx } from "theme-ui";

const ShoppingCartButton = ({ children, handleClick }) => (
  <button
    onClick={handleClick}
    sx={{
      border: "none",
      outline: "none",
      height: "100%",
      width: "100%",
      padding: "0",
      position: "relative",
      cursor: "pointer",
      background: "transparent",
      backgroundColor: "text",
    }}
  >
    <span
      sx={{
        fontSize: 3,
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </span>
  </button>
);

export default ShoppingCartButton;
